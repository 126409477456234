import axios from 'axios'
import { Link } from 'gatsby'
import isEmpty from 'lodash.isempty'
import keys from 'lodash.keys'
import pickBy from 'lodash.pickby'
import React, { useEffect, useState } from 'react'
import crossIcon from '../assets/img/cross.png'
import './../assets/css/drag.css'
import './../assets/css/transport.css'
import uploadSolid from './../assets/img/upload-solid.svg'


const Transport = () => {
  const [formToShow, setFormToShow] = useState('step1')

  const [showSignIframe, setShowSignIframe] = useState(false)

  const [checked, setChecked] = useState(false)

  const [file, setFile] = useState(null)

  const [loading, setLoading] = useState(false)

  const [dataForm, setDataForm] = useState({
    prenom: '',
    nom: '',
    fonction: '',
    email: '',
    telephone: '',
    agrement: '',
    nomDeLetaSociete: '',
    adresse: '',
    codePostal: '',
    ville: '',
    nombreDambulance: '',
    secteurInteresse: ''
  })

  const [dataFormErr, setDataFormErr] = useState({
    nom: false,
    prenom: false,
    nomDeLetaSociete: false,
    fonction: false,
    telephone: false,
    agrement: false,
    adresse: false,
    codePostal: false,
    email: false,
    ville: false,
    nombreDambulance: false,
    secteurInteresse: false,
    file: false,
    checked: false
  })

  // console.log(dataFormErr)

  useEffect(() => {
    window.scrollTo(0, 0)
  }, [formToShow])

  function validateEmail (mail) {
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
    return re.test(String(mail).toLowerCase())
  }

  const handleChange = e => {
    const { value, name } = e.target
    // console.log(name, value)
    setDataForm({
      ...dataForm,
      [name]: value
    })
  }

  const [vehicleSelectedErr, setVehicleSelectedErr] = useState(false)

  const [vehicleSelected, setVehicleSelected] = useState({
    ambulance: false,
    ambulanceParamedicalisee: false,
    taxiConventionne: false,
    vsl: false,
    ambulancePediatrique: false,
    ambulanceBariatrique: false
  })

  const setVehiclesCheck = checkName => {
    return setVehicleSelected({
      ...vehicleSelected,
      [checkName]: !vehicleSelected[checkName]
    })
  }

  const validateStepOne = () => {
    const { nom, prenom, email, telephone, fonction, agrement } = dataForm

    setDataFormErr({
      ...dataFormErr,
      nom: nom.length === 0,
      prenom: prenom.length === 0,
      email: !validateEmail(email),
      telephone: telephone.length === 0,
      fonction: fonction.length === 0,
      agrement: agrement.length === 0
    })
    // console.log(dataFormErr)

    if (nom.length === 0) return false
    else if (!validateEmail(email)) return false
    else if (prenom.length === 0) return false
    else if (telephone.length === 0) return false
    else if (fonction.length === 0) return false
    else if (agrement.length === 0) return false
    else if (formToShow !== 'step3') {
      setFormToShow('step2')
      return true
    } else return true
  }

  const validateStepTwo = () => {
    const { nomDeLetaSociete, adresse, codePostal, ville } = dataForm

    setDataFormErr({
      ...dataFormErr,
      nomDeLetaSociete: nomDeLetaSociete.length === 0,
      adresse: adresse.length === 0,
      codePostal: codePostal.length === 0,
      ville: ville.length === 0,
      file: file === null
    })
    // console.log(dataFormErr)

    if (nomDeLetaSociete.length === 0) return false
    else if (adresse.length === 0) return false
    else if (codePostal.length === 0) return false
    else if (ville.length === 0) return false
    else if (file === null) return false
    else if (formToShow !== 'step3') {
      setFormToShow('step3')
      return true
    } else return true
  }

  const validateStepThree = () => {
    const { nombreDambulance } = dataForm

    const isError = pickBy(vehicleSelected, function (value, key) {
      return value === true
    })

    if (isEmpty(isError)) {
      setVehicleSelectedErr(true)
    } else {
      setVehicleSelectedErr(false)
    }

    // console.log(checked)

    // console.log(nombreDambulance.length === 0)
    setDataFormErr({
      ...dataFormErr,
      nombreDambulance: nombreDambulance.length === 0,
      checked: !checked
    })

    if (isEmpty(isError) || !checked || nombreDambulance.length === 0) return false
    return true
  }

  const handleFileInput = e => {
    // handle validations
    const files = e.target.files[0]
    // console.log(e.target.files[0])
    if (files) {
      setFile(files)
    }
  }

  const dragOver = e => {
    e.preventDefault()
  }

  const dragEnter = e => {
    e.preventDefault()
  }

  const dragLeave = e => {
    e.preventDefault()
  }

  const fileDrop = e => {
    e.preventDefault()
    const files = e.dataTransfer.files[0]
    // console.log(files)
    if (
      files.type === 'application/pdf' ||
      files.type === 'image/png' ||
      files.type === 'image/jpg' ||
      files.type === 'image/jpeg' ||
      files.type ===
        'application/vnd.openxmlformats-officedocument.wordprocessingml.document' ||
      files.type === 'application/msword' ||
      files.type === 'text/plain'
    ) { return setFile(files) }
  }

  const setInitialStates = () => {
    setDataForm({
      prenom: '',
      nom: '',
      fonction: '',
      email: '',
      telephone: '',
      agrement: '',
      nomDeLetaSociete: '',
      adresse: '',
      codePostal: '',
      ville: '',
      nombreDambulance: '',
      secteurInteresse: ''
    })
    setVehicleSelected({
      ambulance: false,
      ambulanceParamedicalisee: false,
      taxiConventionne: false,
      vsl: false,
      ambulancePediatrique: false,
      ambulanceBariatrique: false
    })
    setDataFormErr({
      nom: false,
      prenom: false,
      nomDeLetaSociete: false,
      fonction: false,
      telephone: false,
      agrement: false,
      adresse: false,
      codePostal: false,
      email: false,
      ville: false,
      nombreDambulance: false,
      secteurInteresse: false,
      file: false,
      checked: false
    })
    setChecked(false)
    setFile(null)
  }

  const handleSendEmail = () => {
    const {
      nom,
      email,
      nomDeLetaSociete,
      telephone,
      fonction,
      prenom,
      adresse,
      codePostal,
      agrement,
      ville,
      secteurInteresse,
      nombreDambulance
    } = dataForm

    const vehiclesList = pickBy(vehicleSelected, function (value, key) {
      return value === true
    })

    const formData = new FormData()
    formData.append('Nom :', nom)
    formData.append('Prénom :', prenom)
    formData.append('Fonction :', fonction)
    formData.append('Email :', email)
    formData.append('Téléphone :', telephone)
    formData.append('Agrément :', agrement)
    formData.append('Nom de la societé :', nomDeLetaSociete)
    formData.append('Adresse :', adresse)
    formData.append('Code postal :', codePostal)
    formData.append('Ville :', ville)
    formData.append('Kbis :', file)
    formData.append('Vos véhicules :', JSON.stringify(keys(vehiclesList)))
    formData.append(
      'Nombre d’ambulance(s) paramédicalisée(s) :',
      nombreDambulance
    )
    formData.append('Secteur(s) intéressée(s) :', secteurInteresse)

    setFormToShow('success')

    return axios
      .post('https://usebasin.com/f/50aa6e72c809', formData, {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'multipart/form-data'
        }
      })
      .then(res => {
        // console.log('sended', res)
        if (res.data.success) {
          setInitialStates()
        }
      })
      .catch(err => {
        // console.log("not send", err);
      })
  }

  const sendMsg = e => {
    e.preventDefault()

    const vehiclesList = pickBy(vehicleSelected, function (value, key) {
      return value === true
    })

    let contractType
    if (vehicleSelected.taxiConventionne && Object.keys(vehiclesList).length === 1) {
      contractType = 'taxi'
    } else if (vehicleSelected.taxiConventionne && Object.keys(vehiclesList).length !== 1) {
      contractType = 'mixed'
    } else {
      contractType = 'ambulance'
    }

    // console.log(validateStepThree())

    const isValid =
      validateStepOne() && validateStepTwo() && validateStepThree()
    // console.log('send')

    // console.log(isValid)

    if (isValid) {
      setLoading(true)

      const payload = {
        name: dataForm.nom,
        firstname: dataForm.prenom,
        entreprise: dataForm.nomDeLetaSociete,
        email: dataForm.email,
        contractType,
        completeAdress: `${dataForm.adresse} ${dataForm.codePostal} ${dataForm.ville}`,
        fonction: dataForm.fonction,
        phone: dataForm.telephone
      }

      axios
        .post(
          'https://paramedic-sign.netlify.app/.netlify/functions/eversign',
          {
            body: payload,
            headers: {
              'Content-Type': 'application/json',
              'Access-Control-Allow-Origin': '*'
            }
          }
        )
        .then(res => {
          // console.log('sended:', res)
          // eslint-disable-next-line no-undef
          eversign.open({
            url: res.data.url,
            containerID: 'main_transport',
            events: {
              loaded: function () {
                // console.log('loaded Callback')
                setShowSignIframe(true)
              },
              signed: function () {
                // console.log('signed Callback')
                setShowSignIframe(false)
                setLoading(false)
                return handleSendEmail()
              },
              declined: function () {
                // console.log('declined Callback')
                setShowSignIframe(false)
                setFormToShow('cancel')
              },
              error: function () {
                // console.log('error Callback')
                setFormToShow('step1')
              }
            }
          })
        })
        .catch(err => {
          // console.log("not send", err);
        })
    }
  }
  return (
    <>
      <div
        id='main_transport'
        style={{ display: `${showSignIframe ? '' : 'none'}` }}
      />
      <div
        className='lightbox fade-out'
        style={{ display: `${showSignIframe ? '' : 'none'}` }}
      />
      <section className='form-box'>
        <div className='container'>
          <div className='row'>
            <div className='col-sm-10 col-sm-offset-1 col-md-8 col-md-offset-2 col-lg-6 col-lg-offset-3 form-wizard'>
              {/* Form Wizard */}
              <form onSubmit={sendMsg} encType='multipart/form-data'>
                {/* Form Step 1 */}
                <fieldset
                  className={
                    formToShow === 'step1' ? 'fade-in' : 'fade-out show_form'
                  }
                >
                  <h3>Pour commencer</h3>
                  <p>Apprenons à se connaitre !</p>

                  <div className='form-group'>
                    <label>Prénom*</label>
                    <input
                      value={dataForm.prenom}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='prenom'
                      className={`form-control ${
                        dataFormErr.prenom ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Nom*</label>
                    <input
                      value={dataForm.nom}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='nom'
                      className={`form-control ${
                        dataFormErr.nom ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Fonction*</label>
                    <input
                      value={dataForm.fonction}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='fonction'
                      className={`form-control ${
                        dataFormErr.fonction ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Adresse éléctronique*</label>
                    <input
                      value={dataForm.email}
                      onChange={e => handleChange(e)}
                      type='mail'
                      name='email'
                      className={`form-control ${
                        dataFormErr.email ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Numéro de téléphone*</label>
                    <input
                      value={dataForm.telephone}
                      onChange={e => handleChange(e)}
                      type='number'
                      name='telephone'
                      className={`form-control ${
                        dataFormErr.telephone ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Numéro d’agrément*</label>
                    <input
                      value={dataForm.agrement}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='agrement'
                      className={`form-control ${
                        dataFormErr.agrement ? 'invalid' : ''
                      }`}
                    />
                  </div>

                  <div className='form-wizard-buttons'>
                    <button
                      onClick={() => validateStepOne()}
                      type='button'
                      className='btn btn-next'
                    >
                      Prochaine étape
                    </button>
                  </div>
                </fieldset>

                {/* Form Step 2 */}
                <fieldset
                  className={
                    formToShow === 'step2' ? 'fade-in' : 'fade-out show_form'
                  }
                >
                  <h3>Il nous en faut un petit peu plus...</h3>
                  <div className='form-group top_2nd_form'>
                    <label>Nom de la société*</label>
                    <input
                      value={dataForm.nomDeLetaSociete}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='nomDeLetaSociete'
                      className={`form-control ${
                        dataFormErr.nomDeLetaSociete ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Adresse*</label>
                    <input
                      value={dataForm.adresse}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='adresse'
                      className={`form-control ${
                        dataFormErr.adresse ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Code postal*</label>
                    <input
                      value={dataForm.codePostal}
                      onChange={e => handleChange(e)}
                      type='number'
                      name='codePostal'
                      className={`form-control ${
                        dataFormErr.codePostal ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>Ville*</label>
                    <input
                      value={dataForm.ville}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='ville'
                      className={`form-control ${
                        dataFormErr.ville ? 'invalid' : ''
                      }`}
                    />
                  </div>

                  <div id='upload_transport_zone'>
                    <p>Ajouter un extrait Kbis (- de 3 mois)</p>
                    <div
                      className={`drop-zone row ${
                        dataFormErr.file ? 'invalid-file' : ''
                      }`}
                      onDragOver={dragOver}
                      onDragEnter={dragEnter}
                      onDragLeave={dragLeave}
                      onDrop={fileDrop}
                    >
                      <input
                        onChange={handleFileInput}
                        id='myFile'
                        type='file'
                        name='cv'
                        className='drop-zone__input'
                        accept='image/*,.pdf,.doc,.docx'
                      />
                      <div className='col-md-12'>
                        <img
                          draggable='false'
                          src={uploadSolid}
                          className='upload_icon'
                          alt='icône téléchargement'
                        />
                      </div>
                      <div className='col-md-12'>
                        <span
                          style={{ color: '#2FABE0' }}
                          className='drop-zone__prompt'
                        >
                          Parcourir...
                        </span>
                      </div>
                    </div>
                    {file && (
                      <p style={{ textAlign: 'center', marginTop: '0.5em' }}>
                        {file.name}
                        <span onClick={() => setFile(null)}>
                          <img
                            style={{
                              marginLeft: '1em',
                              marginBottom: '0.2em',
                              cursor: 'pointer',
                              width: '1.2em'
                            }}
                            src={crossIcon}
                            alt='paramedic'
                          />
                        </span>
                      </p>
                    )}
                  </div>

                  <div className='form-wizard-buttons'>
                    <button
                      onClick={() => setFormToShow('step1')}
                      type='button'
                      className='btn btn-previous mr-1'
                    >
                      Retour
                    </button>
                    <button
                      onClick={() => validateStepTwo()}
                      type='button'
                      className='btn btn-next ml-1'
                    >
                      Prochaine étape
                    </button>
                  </div>
                </fieldset>

                {/* Form Step 3 */}
                <fieldset
                  className={
                    formToShow === 'step3' ? 'fade-in' : 'fade-out show_form'
                  }
                >
                  <h3>Et le dernier pour la route !</h3>
                  {/* <div style="clear:both;"></div> */}
                  <div className='form-group radio_list'>
                    <p>Quels sont vos véhicule(s) ?*</p>
                    <div
                      className='row'
                      style={{
                        border: `2px solid ${
                          vehicleSelectedErr ? 'red' : 'transparent'
                        }`,
                        borderRadius: '10px'
                      }}
                    >
                      <div className='col-sm-6'>
                        <div className='form-check'>
                          <input
                            onChange={() => setVehiclesCheck('ambulance')}
                            className='form-check-input'
                            type='checkbox'
                            id='gridCheck1'
                            checked={vehicleSelected.ambulance}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='gridCheck1'
                          >
                            Ambulance
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            onChange={() =>
                              setVehiclesCheck('ambulanceParamedicalisee')}
                            className='form-check-input'
                            type='checkbox'
                            id='gridCheck2'
                            checked={vehicleSelected.ambulanceParamedicalisee}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='gridCheck2'
                          >
                            Ambulance Paramédicalisée
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            onChange={() =>
                              setVehiclesCheck('taxiConventionne')}
                            className='form-check-input'
                            type='checkbox'
                            id='gridCheck3'
                            checked={vehicleSelected.taxiConventionne}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='gridCheck3'
                          >
                            Taxi Conventionné
                          </label>
                        </div>
                      </div>
                      <div className='col-sm-6'>
                        <div className='form-check'>
                          <input
                            onChange={() => setVehiclesCheck('vsl')}
                            className='form-check-input'
                            type='checkbox'
                            id='gridCheck4'
                            checked={vehicleSelected.vsl}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='gridCheck4'
                          >
                            VSL
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            onChange={() =>
                              setVehiclesCheck('ambulancePediatrique')}
                            className='form-check-input'
                            type='checkbox'
                            id='gridCheck5'
                            checked={vehicleSelected.ambulancePediatrique}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='gridCheck5'
                          >
                            Ambulance Pédiatrique
                          </label>
                        </div>
                        <div className='form-check'>
                          <input
                            onChange={() =>
                              setVehiclesCheck('ambulanceBariatrique')}
                            className='form-check-input'
                            type='checkbox'
                            id='gridCheck6'
                            checked={vehicleSelected.ambulanceBariatrique}
                          />
                          <label
                            className='form-check-label'
                            htmlFor='gridCheck6'
                          >
                            Ambulance Bariatrique
                          </label>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className='form-group'>
                    <label>Nombre de véhicule(s) paramédicalisée(s):*</label>
                    <input
                      value={dataForm.nombreDambulance}
                      onChange={e => handleChange(e)}
                      type='text'
                      name='nombreDambulance'
                      className={`form-control ${
                        dataFormErr.nombreDambulance ? 'invalid' : ''
                      }`}
                    />
                  </div>
                  <div className='form-group'>
                    <label>
                      Quel(s) secteur(s) vous intéresse(nt) ? (Facultatif)
                    </label>
                    <p className='label_info'>
                      Veuillez renseigner chaque ville espacé d’une virgule (,)
                      ex: Suresnes, 75015...
                    </p>
                    <textarea
                      value={dataForm.secteurInteresse}
                      onChange={e => handleChange(e)}
                      name='secteurInteresse'
                      className='form-control'
                      style={{ height: '12rem' }}
                    />
                  </div>
                  <div className='form-group'>
                    <div className='form-check'>
                      <input
                        onChange={() => setChecked(!checked)}
                        checked={checked}
                        className={`form-check-input ${
                          dataFormErr.checked ? 'ckeckox_invalid' : ''
                        }`}
                        name='validate'
                        type='checkbox'
                        value=''
                        id='invalidCheck'
                      />
                      <label
                        className='form-check-label'
                        htmlFor='invalidCheck'
                      >
                        J’accepte les{' '}
                        <Link to='/mentions-legales'>mentions légales</Link> et
                        la{' '}
                        <Link to='/confidentialite'>
                          politique de confidentialité
                        </Link>
                        .
                      </label>
                      <div className='invalid-feedback'>
                        Vous devez accepter avant de soumettre le formulaire.
                      </div>
                    </div>
                  </div>
                  <br />
                  <div className='form-wizard-buttons'>
                    <button
                      onClick={() => setFormToShow('step2')}
                      type='button'
                      className='btn btn-previous mr-1'
                    >
                      Retour
                    </button>
                    <button
                      onClick={() => validateStepThree()}
                      type='submit'
                      className='btn btn-submit ml-1'
                      style={{ width: '18em!important' }}
                    >
                      {loading ? (
                        <div
                          className='spinner-border text-light mt-1'
                          role='status'
                        />
                      ) : (
                        'Signer mon contrat'
                      )}
                    </button>
                  </div>
                </fieldset>

                {dataFormErr.telephone ||
                dataFormErr.nomDeLetablissement ||
                dataFormErr.agrement ||
                dataFormErr.nom ||
                dataFormErr.prenom ||
                dataFormErr.email ||
                dataFormErr.adresse ||
                dataFormErr.fonction ||
                dataFormErr.ville ||
                dataFormErr.codePostal ||
                dataFormErr.nombreDambulance ||
                dataFormErr.secteurInteresse ||
                vehicleSelectedErr ? (
                  <div style={{ height: '3em', marginTop: '1em' }}>
                    <p className='success' style={{ backgroundColor: 'red' }}>
                      Veuillez remplir les champs indiquer en rouge
                    </p>
                  </div>
                    ) : null}

                <div
                  style={{
                    height: '23em',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  className={
                    formToShow === 'success' ? 'fade-in' : 'fade-out show_form'
                  }
                >
                  <p
                    style={{
                      fontSize: '1.5em',
                      color: '#2fabe0',
                      fontWeight: 'bold',
                      lineHeight: '1.3'
                    }}
                  >
                    Votre demande a bien été prise en compte, Vous serez
                    contacter dans les plus brefs délais. <br /> <br />A très
                    bientôt .
                  </p>
                  <Link to='/'>
                    <button className='btn btn-submit mt-5'>
                      Revenir a l'acceuil
                    </button>
                  </Link>
                </div>

                <div
                  style={{
                    height: '23em',
                    display: 'flex',
                    alignItems: 'center',
                    flexDirection: 'column',
                    justifyContent: 'center'
                  }}
                  className={
                    formToShow === 'cancel' ? 'fade-in' : 'fade-out show_form'
                  }
                >
                  <p
                    style={{
                      fontSize: '1.7em',
                      color: 'red',
                      fontWeight: 'bold',
                      lineHeight: '1.5'
                    }}
                  >
                    La souscription ne peut être validé tant que vous n’avez pas
                    signé.
                  </p>
                  <Link to='/'>
                    <button className='btn btn-submit mt-5'>
                      Retour à l'acceuil
                    </button>
                  </Link>
                </div>
              </form>
            </div>
          </div>
        </div>
      </section>
    </>
  )
}

export default Transport
